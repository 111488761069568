/* eslint-disable jsx-a11y/anchor-is-valid */
//imports from libraries
import React, { useState, useEffect, useCallback, } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown } from "primereact/dropdown";
import { useTranslation } from "react-i18next";
import { Card } from 'primereact/card';
import { Column } from "primereact/column";
import { DataTable } from 'primereact/datatable';
import { Paginator } from 'primereact/paginator';
import { Button } from "primereact/button";
// imports from redux
import DefMembershipActions from "../../../Redux/actions/defMembership-actions";
import SettlementActions from "../../../Redux/transition/settlement/settlement.reducer";
import WalletActions from "../../../Redux/transition/wallet/wallet.reducer";
import PayoutChannelActions from "../../../Redux/actions/payoutchannel-actions";
import BankActions from "../../../Redux/actions/bank-actions";
//imports from components and styles
import "../../../Styles/pages/transactions.scss";
import "../../../Styles/pages/activities.scss";
import Summary from "../index";
import moment from "moment";
import { RequestedFilterTemplate } from "../../Reports/Templates";

const TransactionScreen = () => {
	const [expandedRows, setExpandedRows] = useState(null);
	const [query, setQuery] = useState([])
	const [id, setId] = useState([])
	const [selected, setSelected] = useState([])

	const params = new URLSearchParams(window.location.search);
	const payoutChannelId = params.get('payoutChannelId')

	// ...redux state
	const defMembership = useSelector((state) => state.defMembership.defMembership);
	const dispatch = useDispatch()
	// Transaction Redux
	const settlements = useSelector((state) => state.settlements.settlements);
	const settlementsCount = useSelector((state) => state.settlements.count);
	const fetchingSettlementsCount = useSelector((state) => state.settlements.fetchingCount);
	const fetchingSettlements = useSelector((state) => state.settlements.fetchingAll);
	const payoutChannelsSaved = useSelector((state) => state.payoutChannels.payoutChannelsSaved);
	const payoutChannelsAll = useSelector((state) => state.payoutChannels.payoutChannels);
	const banks = useSelector((state) => state.banks.banks);

	const getAllSettlement = useCallback((businessId, filter, options) => {dispatch(SettlementActions.settlementAllRequest(businessId, filter, options));},[dispatch]);
	const getSettlementsCount = useCallback((businessId, filter) => {dispatch(SettlementActions.settlementCountRequest(businessId, filter));},[dispatch]);
	const getMembership = useCallback(() => {dispatch(DefMembershipActions.defMembershipRequest());}, [dispatch]);
	const getWallet = useCallback((businessId) => { dispatch(WalletActions.walletRequest(businessId)) }, [dispatch]);
	const getSavedPayoutChannels = useCallback((businessId, options) => dispatch(PayoutChannelActions.payoutChannelSavedRequest(businessId, options)), [dispatch]);
	const getAllPayoutChannels = useCallback((businessId, options) => dispatch(PayoutChannelActions.payoutChannelAllRequest(businessId, options)), [dispatch]);
	const getAllBanks = useCallback((countryId) => dispatch(BankActions.bankAllRequest(countryId)), [dispatch]);

	const { t } = useTranslation("common");
	const [state, setState] = useState({
		loading: true,
		count: null,
		size: 15,
		page: 0,
		first: 0,
		sort: 'requestedOnDate,desc',
		status: null,
		startDate: null,
		channel: null,
		selectedChannel: {},
		payoutChannels: [],
		payoutChannelAll: [],
		channelType: null,
		channelId: null,
		channelName: null,
		endDate: moment(new Date()).format('YYYY-MM-DD'),
		filterItems: [
			{ label: t("subscriber.no_filter"), value: null },
			{ label: t("subscriber.seven_days"), value: "7", },
			{ label: t("subscriber.two_weeks"), value: "14", },
			{ label: t("subscriber.one_month"), value: "30", },
			{ label: t("subscriber.quarter_year"), value: "90", },
			{ label: t("subscriber.half_year"), value: "182", },
			{ label: t("subscriber.one_year"), value: "365", },
			{ label: t("subscriber.two_years"), value: "730", },
		],
		filterStatusItems: [
			{ label: t("subscriber.no_filter"), value: null },
			{ label: t("subscriber.Pending"), value: "PENDING" },
			{ label: t("subscriber.Completed"), value: "PROCESSED" },
			{ label: t("subscriber.reversed"), value: "REVERSED" },
		],
		filterChannelItems: [
			{ label: t("subscriber.no_filter"), value: null },
			{ label: t("subscriber.Bank"), value: "BANK" },
			{ label: t("subscriber.Mobile"), value: "MOBILE" },
		],
		channels: [{ label: t("subscriber.no_filter"), value: null }],
	});

	const businessId = defMembership.business.id;
	const getDate = (day) => {
		let ourDate = new Date();
		// Gets x number of days in the past.
		let pastDate = ourDate.getDate() - day;
		return moment(ourDate.setDate(pastDate)).format('YYYY-MM-DD');
	}

	useEffect(() => {
		if (!defMembership) {
			getMembership();
		}
		if (defMembership) {
			getWallet(businessId);
			getSavedPayoutChannels(businessId)
			getAllPayoutChannels(businessId)
			getAllBanks(defMembership.business.country.id)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	useEffect(() => {
		if (banks) {
			setState({
				...state,
				channels: [
					...[...banks.map(({ name, id }) => ({ label: name, value: id }))]
						.sort((a, b) => (a.label < b.label ? -1 : 1))
				],
			})
		}
		if (payoutChannelsSaved) {
			setState((state) => { return { ...state, payoutChannels: payoutChannelsSaved } })
		}
		if (payoutChannelsAll) {
			setState((state) => { return { ...state, payoutChannelAll: payoutChannelsAll } })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [banks, payoutChannelsSaved, state.payoutChannels, payoutChannelsAll])

	useEffect(() => {
		if (!defMembership) {
			getMembership();
		}
		if (defMembership) {
			const options = `&page=${state.page}&size=${state.size}&sort=${state.sort}`
			let query = ""
			const appendQuery = (param) => {
				query = query ? query + "&" + param : param
			}
			if (id) appendQuery(`channelId=${id}`)
			if (payoutChannelId) appendQuery(`channelId=${payoutChannelId}`)
			if (state.startDate) appendQuery(`startDate=${getDate(state.startDate)}&endDate=${state.endDate}`)
			if (state.status) appendQuery(`status=${state.status}`)
			if (state.channelType) appendQuery(`channelType=${state.channelType}`)
			if (state.channel) appendQuery(`systemBankId=${state.channel}`)
			query = `?${query}${options}`
			getAllSettlement(businessId, query);
			getSettlementsCount(businessId, query);
		}
	}, [businessId, defMembership, getAllSettlement, getMembership, getSettlementsCount, state.channel,
		state.channelId, state.channelType, state.endDate, state.page,
		state.size, state.sort, state.startDate, state.status, payoutChannelId, id]);

	useEffect(() => {
		if (payoutChannelId) {
			setState((state) => { return { ...state, selectedChannel: PreSelectPayoutChannel } })
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [payoutChannelId, state.selectedChannel])

	const onStatusSearch = (e) => {
		setState({ ...state, status: e.value, page: 0, first: 0 })
	}
	const onDateChange = (e) => {
		setState((state) => ({ ...state, startDate: e.value, page: 0, first: 0 }))
	}

	const PreSelectPayoutChannel = state?.payoutChannels?.find(({ id }) => id === parseInt(payoutChannelId)) || null

	//the loadingTable variable here controls the spinner in the table
	const loadingTable = !defMembership || fetchingSettlements || fetchingSettlementsCount

	const statusTemplate = (rowData) => {
		if (rowData.status === 0) {
			return "";
		} else {
			if (rowData.status === "PROCESSED") {
				return <div className="flex flex-column equal-margin -ml-4">
					<span className={`status-outline prossessed  status-${(rowData.status ? rowData.status.toLowerCase() : '')}  mb-1`} >{rowData.status}</span>
					{rowData.status === "PROCESSED" ? <span className="trans-normal-text" style={{ display: "flex", flexDirection: "column" }}>
						<span className="processed_on_header text-right mb-1">{t("transaction.processed_on")}</span>
					</span> : null}
					<span id="processed_on" className="text-right">{moment(rowData.processedOn).format("ddd, MMM D, YYYY HH:MM, A")}</span>
				</div>
			}
			else if (rowData.status === "PENDING") {
				return <div className="flex flex-column equal-margin -ml-4">
					<span className={`status-outline status-${(rowData.status ? rowData.status.toLowerCase() : '')}  mb-1`}>{rowData.status}</span>
					{rowData.status === "PENDING" ? <span className="trans-normal-text" style={{ display: "flex", flexDirection: "column" }}>
						<span className="requested_on_header text-right mb-1">{t("transaction.requested_on")}</span>
					</span> : null}
					<span id="requested_on" className="text-right">{moment(rowData.requestedOn).format("ddd, MMM D, YYYY HH:MM, A")}</span>
				</div>
			}
			else if (rowData.status === "REVERSED") {
				return <div  className="status flex flex-column equal-margin -ml-4">
					<span className={`status-outline status-reversed status-${(rowData.status ? rowData.status.toLowerCase() : '')}  mb-1`}>{rowData.status}</span>
					<span style={{ fontSize: "10px", height: "auto", marginTop: "1rem" }} className="reversalReason reversed_status">
						{rowData.reversalReason}
					</span>
					{rowData.status === "REVERSED" ? <span className="trans-normal-text" style={{ display: "flex", flexDirection: "column" }}>
						<span className="processed_on_header text-right mb-1">{t("transaction.reversed_on")}</span>
					</span> : null}
						<span id="processed_on" className="text-right">{moment(rowData.reversedOn).format("ddd, MMM D, YYYY HH:MM, A")}</span>
				</div>
			}
		}
	}

	const activityTemplate = (rowData) => {
		if (rowData !== null) {
			return <div className="equal-margin">
				<div style={{ display: "flex", marginBottom: "0.5rem" }}>
					<span className="trans-normal-text reference">{rowData.reference === null ? "--" : rowData.reference}</span>
				</div>
				{/* <div style={{ display: "flex", textAlign: "left", flexDirection: "column" }}>
					{rowData.status === "PENDING" ? <span className="trans-normal-text" style={{ display: "flex", flexDirection: "column" }}>
						<span className="requested_on_header">{t("transaction.requested_on")}</span>
					</span> : null}
					{rowData.status === "PROCESSED" ? <span className="trans-normal-text" style={{ display: "flex", flexDirection: "column" }}>
						<span className="processed_on_header">{t("transaction.processed_on")}</span>
					</span> : null}
					{rowData.status === "REVERSED" ? <span className="trans-normal-text" style={{ display: "flex", flexDirection: "column" }}>
						<span className="processed_on_header">{t("transaction.reversed_on")}</span>
					</span> : null}
				</div> */}
			</div>
		}
		return "--"
	}
	const amountTemplate = (rowData) => {
		const amountPaid = `${rowData.requested.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
		const fee = `${rowData.fee.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
		if (rowData.requested) {
			return <div style={{display:"flex", flexDirection:"column", alignItems:"start"}}>
				<span className="trans-normal-text">
					{`${defMembership.business.country.currency} ${amountPaid}`}
			 </span>
			 <span className="fee" style={{marginTop:"0.2rem"}}>
			 		{t("transaction.fee")}
					<span className="fee1">{`:${defMembership.business.country.currency} ${fee}`}</span>
			 </span>
			 </div>
		} else {
			return "--"
		}
		
	}

	const customerBodyTemplate = (rowData) => {
		return <div className="flex equal-margin">
			<div className="channel-logo">
				<div style={{ display: 'flex', flexDirection: "column" }}>
					<div>
						<span severity="info" id="channel_type">
							{rowData.channelType ? rowData.channelType : "--"}
						</span>
					</div>
					<div>
						{rowData.channelType ?
							<img alt="channel logo" src={rowData.sysBankLogoUrl} width='50rem' height='40rem' /> : null
						}
					</div>
				</div>
			</div>
			<div style={{textAlign: "start" }}>
				{rowData.channelType ?
					<span className="account_name">
						{rowData.channelType !== null ? rowData?.channelName : "--"}</span> : null
				}
				<div style={{ display: 'flex', flexDirection: "column" }}>
					{rowData.channelType ?
						<span className="account_number">
							{rowData.channelType !== null && rowData.channelName === null ?
								<span style={{ fontWeight: "bold", color: "#000" }}>{rowData.channelIdentifier}</span> : rowData.channelIdentifier}
						</span> : null
					}
				</div>
				<div className="channel-info_div">
					<div className="channel-info" >
						<span id="channel_name">
							{rowData.channelType ? rowData.sysBankName : "--"}
						</span>
					</div>
				</div>
			</div>
		</div>
	}

	const requestedByTemplate = (rowData) => {
		return <div className="customerBodyTemplate equal-margin">
			{rowData.requestedByName ?
				<span className="requested-info">{rowData.requestedByName}</span> : "--"
			}
			{rowData.requestedByLogin ?
				<span className="requested_normal-info">{rowData.requestedByLogin}</span> : "--"
			}
		</div>
	}

	const columns = [
		{ field: "requested", body: amountTemplate, header: t("transaction.amount") },
	]

	const onPageChange = (e) => {
		if (e.pageCount <= 1) {
			return;
		} setState((state) => ({ ...state, page: e.page, first: e.first }));
	}

	const rowExpansionTemplate = (data) => {
		return (
			<DataTable id="expanded-table"
				className="p-datatable-responsive-table" value={data.sources} responsiveLayout="scroll">
				<Column field="groupName" header={t("transaction.product_account_name")} className={`datatable-cell-default`}
					headerClassName="datatable-header-default" body={(rowData) => (
						<div className="flex items-start equal-margin">
						  <p>{rowData.groupName}</p>
						</div>
					  )}></Column>

				<Column field="deduction" header={t("amount")} body={(rowData) =>
					rowData.deduction ? <div className="flex flex-column equal-margin" style={{alignItems:"flex-start"}}>
						<span className="trans-normal-text-1">{`${defMembership.business.country.currency} ${rowData.deduction.toFixed(2)}`}</span>
						<span className="fee" style={{marginTop:"0.2rem"}}>
							{t("transaction.fee")}
							<span className="fee1">{`:${defMembership.business.country.currency} ${rowData.fee.toFixed(2)}`}</span>
							</span>
						</div>
						: "--"} className={`datatable-cell-default`} headerClassName="datatable-header-default"></Column>
			</DataTable >
		);
	}
	const allowExpansion = (rowData) => {
		return rowData.sources?.length > 0;
	};

	const headerStyle = {
		fontSize: "12px",
		fontFamily: "Roboto",
		fontWeight: "bold",
		color: "#666678",
		textTransform: "capitalize",
	}

	return (
		<>
			<Summary />
			<Card style={{ marginTop: "2rem", paddingRight: '2rem' }} id="filters-card">
				<div className="p-grid p-fluid main-div">
					<div className="activity-actions-count">
						<span className="activity-actions-count-number count" id="count" >
							{settlementsCount > 0 ? settlementsCount : 0}
						</span>
						<span className="activity-actions-count-text" id="count-text">
							{t("withdrawals_reports.withdrawals")}
						</span>
					</div>
					<>
						<div className="p-col-12 p-md-3 filters" >
							<div className="p-inputgroup">
									<RequestedFilterTemplate {...{ state, setState, t, setQuery, payoutChannelsAll, query, id, setId, Button, selected, setSelected}} />
							</div>
						</div>
						<div className="p-col-12 p-md-4 filters" >
							<div className="p-inputgroup">
								<Dropdown
									className="activity-dropdown"
									style={{width:"17rem"}}
									value={state.channel}
									options={state.channels}
									placeholder={t("transaction.filter_by_channel")}
									filter
									showClear
									filterBy='name'
									onChange={(e) => {
										setState({ ...state, channel: e.value, page: 0, first: 0 })
									}}
								/>
							</div>
						</div>
						<div className="p-col-12 p-md-4 filters" >
							<div className="p-inputgroup">
								<Dropdown
									className="activity-dropdown"
									style={{width:"10rem"}}
									value={state.channelType}
									options={state.filterChannelItems}
									placeholder={t("transaction.filter_by_channel_type")}
									onChange={(e) => {
										setState({ ...state, channelType: e.value, page: 0, first: 0 })
									}}
								/>
							</div>
						</div>
						<div className="p-col-12 p-md-3 filters" >
							<div className="p-inputgroup">
								<Dropdown
									className="activity-dropdown"
									style={{width:"11rem"}}
									value={state.startDate}
									options={state.filterItems}
									placeholder={t("transaction.filter_by_time")}
									onChange={onDateChange}
								/>
							</div>
						</div>
						<div className="p-col-12 p-md-4 filters" >
							<div className="p-inputgroup">
								<Dropdown
									className="activity-dropdown"
									style={{width:"12rem"}}
									value={state.status}
									options={state.filterStatusItems}
									placeholder={t("transaction.filter_by_status")}
									onChange={onStatusSearch}
								/>
							</div>
						</div>
					</>
				</div >
			</Card>
			<div>
				<DataTable
					id="data_table"
					value={settlements}
					className="p-datatable-responsive-table transactions-table"
					responsiveLayout="stack" breakpoint="1050px"
					paginator={false}
					dataKey="id"
					loading={loadingTable}
					expandedRows={expandedRows}
					rowExpansionTemplate={rowExpansionTemplate}
					onRowToggle={(e) => setExpandedRows(e.data)}
				>
					<Column
						headerClassName="datatable-header-default"
						expander={allowExpansion}
						className={`datatable-cell-default chevron`} />

					<Column field="status" body={statusTemplate} header={t("transaction.status")} headerStyle={headerStyle}
						style={{ width: "10rem", border: "0px", borderBottom: "1px solid #e0e0e0", marginLeft: "0rem" }} />

					<Column field="requestedOnDate" header={t("transaction.withdrawal_id_header")}
						body={activityTemplate} headerClassName="activityTemplateHeader" headerStyle={headerStyle}
						style={{ padding: "14px 0px 14px 0px", border: "0px", borderBottom: "1px solid #e0e0e0" }} />

					<Column field="requestedBy" body={requestedByTemplate} header={t("transaction.requested_by")}
						style={{ width: "18rem", border: "0px", borderBottom: "1px solid #e0e0e0" }} headerStyle={headerStyle} />

					<Column field="recipientAccount" body={customerBodyTemplate} header={t("transaction.recipient")}
						style={{ width: "26rem", border: "0px", borderBottom: "1px solid #e0e0e0" }} headerStyle={headerStyle} />

					{columns.map(({ field, body, header, sort, style, className }, i) =>
						<Column key={i} field={field} body={body} header={header} alignHeader="left"
							headerStyle={headerStyle} sortable={sort}
							className={`datatable-cell-default ${className}`} style={{ ...style }} />
					)}
				</DataTable>
				<Paginator className="paginator" rows={settlements?.length === 0 ? 0 : state.size} totalRecords={settlementsCount}
					first={state.first} onPageChange={onPageChange} >
				</Paginator>
			</div>
		</>
	)
}
export default TransactionScreen;